<template>
  <div class="sys-config-page">
    <div class="sys-config-page-conten">
      <fm-title title-text="统计配置"></fm-title>
      <fm-form style="margin:0 2rem 0 10px;" :inline="false" label-align="left">
        <fm-form-item label="所属层级">
          <fm-select @change="valueChange" absolute filterable v-model="countConfig.areaLevel">
            <fm-option v-for="item in areaLevelList" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="是否在编规则">
          <fm-select @change="valueChange" absolute filterable v-model="countConfig.iszb">
            <fm-option v-for="item in iszbRule" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="是否编外聘用人员">
          <fm-select @change="valueChange" absolute filterable v-model="countConfig.isBwpy">
            <fm-option v-for="item in isBwpyRule" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="特设岗位人员 对应 人员类别">
          <fm-select @change="valueChange" multiple absolute filterable v-model="countConfig.isTgts">
            <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="劳务派遣人员 对应 人员类别">
          <fm-select @change="valueChange" multiple absolute filterable v-model="countConfig.isNwpq">
            <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="专业技术人员专业大类-默认值">
          <fm-select @change="valueChange" absolute filterable v-model="countConfig.bigProType">
            <fm-option v-for="item in bigProTypeList" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="专业技术人员专业大类-工程技术人员 对应 专业技术人员专业类别">
          <fm-select @change="valueChange" multiple absolute filterable v-model="countConfig.bigProTypeGcjs">
            <fm-option v-for="item in zyjsryzylb" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="专业技术人员专业大类-卫生技术人员 对应 专业技术人员专业类别">
          <fm-select @change="valueChange" multiple absolute filterable v-model="countConfig.bigProTypeWsjs">
            <fm-option v-for="item in zyjsryzylb" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="专业技术人员专业大类-会计人员 对应 专业技术人员专业类别">
          <fm-select @change="valueChange" multiple absolute filterable v-model="countConfig.bigProTypeKj">
            <fm-option v-for="item in zyjsryzylb" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="专业技术人员专业大类-其他人员 对应 专业技术人员专业类别">
          <fm-select @change="valueChange" multiple absolute filterable v-model="countConfig.bigProTypeOther">
            <fm-option v-for="item in zyjsryzylb" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
      </fm-form>
    </div>
  </div>
</template>

<script>
import {
  sysConfigRequest
} from '@/api'

export default {
  data () {
    return {
      iszbRule: [{key: 'normal', label: '是否编为在编'}, {key: 'tc', label: '是否编为在编且人员类别不为临聘人员'}],
      isBwpyRule: [{key: 'normal', label: '是否劳务派遣人员为否且是否在编为非在编'}, {key: 'tc', label: '是否在编为否'}],
      bigProTypeList: ['工程技术人员', '卫生技术人员', '会计人员', '其他人员', '为空'],
      areaLevelList: ['中央', '省（区、市）', '地（市、州、盟）', '县（市、区、旗）', '乡（镇）'],
      sData: null,
      loading: true,
      countConfig: {
        areaLevel: null,
        iszb: '',
        isBwpy: '',
        isTgts: [],
        isNwpq: [],
        bigProType: '',
        bigProTypeGcjs: [],
        bigProTypeWsjs: [],
        bigProTypeKj: [],
        bigProTypeOther: []
      }
    }
  },
  methods: {
    async loadData () {
      let res = await sysConfigRequest.get({type: 'sysConfig', key: 'hrms_count_config'})
      if (res && res.length > 0) {
        let value = res[0].value ? JSON.parse(res[0].value) : null
        this.sData = res[0].value
        if (value) {
          Object.keys(this.countConfig).forEach(key => {
            if (['isTgts','isNwpq', 'bigProTypeGcjs', 'bigProTypeWsjs', 'bigProTypeKj', 'bigProTypeOther'].includes(key)) {
              this.countConfig[key] = value[key] ? value[key].split(',') : []
            } else {
              this.countConfig[key] = value[key]
            }
          })
        }
      }
    },
    async valueChange () {
      if (this.loading) {
        return
      }
      let d = {}
      Object.keys(this.countConfig).forEach(key => {
        if (['isTgts', 'isNwpq', 'bigProTypeGcjs', 'bigProTypeWsjs', 'bigProTypeKj', 'bigProTypeOther'].includes(key)) {
          d[key] = this.countConfig[key] ? this.countConfig[key].join(',') : ''
        } else {
          d[key] = this.countConfig[key]
        }
      })
      if (this.sData === JSON.stringify(d)) {
        return
      }
      await sysConfigRequest.add({
        type: 'sysConfig',
        configKey: 'hrms_count_config',
        name: '统计配置',
        remark: '',
        value: JSON.stringify(d)
      })
      this.loadData()
    }
  },
  computed: {
    zyjsryzylb () {
      return this.$store.getters.selectDatas['zyjsryzylb']
    },
    workerJobTypes () {
      return this.$store.getters.selectDatas['worker_job_type']
    }
  },
  created () {
    this.loadData()
    let vm = this
    setTimeout(() => {
      vm.loading = false
    }, 1000)
  }
}
</script>

<style scoped lang="less">
.sys-config-page {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .sys-config-page-conten {
    margin: 20px;
    background: #fff;
    border-radius: 8px;
    padding-bottom: 20px;
  }
}
.config-item {
  min-height: 5rem;
  border-bottom: 1px solid #eee;
}
.config-item {
  display: flex;
  min-height: 3rem;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  .c-label {
    width: 15rem;
    display: flex;
    align-items: center;
  }
  .c-remark {
    flex: 3;
    display: flex;
    align-items: center;
  }
  .c-value {
    width: 15rem;
    display: flex;
    margin-left: 2rem;
    align-items: center;
  }
}
</style>